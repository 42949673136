import React, { useState, useContext } from 'react';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import './AuthPage.scss';

const AuthPage = ({ history }) => {
    const [form, setForm] = useState({
        name: '', // Додано поле Name
        email: '',
        password: '',
        role: 'Farm'  // Додаємо поле для вибору ролі з дефолтним значенням
    });

    const { login } = useContext(AuthContext);

    const changeHandler = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const registerHandler = async () => {
        try {
            const response = await axios.post('/api/auth/registration', form, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            console.log(response.data); 
            history.push('/');
        } catch (error) {
            console.log(error);
        }
    };

    const loginHandler = async () => {
        try {
            const response = await axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/auth/login', { ...form }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            const { token, userId, role, name } = response.data;
            login(token, userId, role, name); // Переконайтеся, що name передається
        } catch (error) {
            console.log(error);
        }
    };
    
    
    
    

    return (
        <BrowserRouter>
            <Switch>
                <React.Fragment>
                    <div className="container">
                        <div className="auth-page">
                            <Route path='/login'>
                                <h3>Authorization</h3>
                                <form className="form form-login">
                                    <div className="row">
                                        <div className="input-field col s12">
                                            <input 
                                                type="email" 
                                                name="email"
                                                className="validate"
                                                onChange={changeHandler}
                                            />
                                            <label htmlFor="email">Email</label>
                                        </div>
                                        <div className="input-field col s12">
                                            <input 
                                                type="password" 
                                                name="password"
                                                className="validate"
                                                onChange={changeHandler}
                                            />
                                            <label htmlFor="password">Password</label>
                                        </div>
                                        <div className="row">
                                            <button
                                                type='button'
                                                className="login-reg-button-width"
                                                onClick={loginHandler}
                                            >
                                                Login
                                            </button>
                                        {/* <Link to="/registration" className="btn-outline btn-reg login-reg-link">No Account?</Link>*/} 
                                        </div>
                                    </div>
                                </form>
                            </Route>

                          {/*  <Route path='/registration'>
                                <h3>Registration</h3>
                                <form className="form form-login">
                                    <div className="row">
                                        <div className="input-field col s12">
                                            <input 
                                                type="text" 
                                                name="name" // Поле для введення Name
                                                className="validate"
                                                onChange={changeHandler}
                                            />
                                            <label htmlFor="name">Name</label>
                                        </div>
                                        <div className="input-field col s12">
                                            <input 
                                                type="email" 
                                                name="email"
                                                className="validate"
                                                onChange={changeHandler}
                                            />
                                            <label htmlFor="email">Email</label>
                                        </div>
                                        <div className="input-field col s12">
                                            <input 
                                                type="password" 
                                                name="password"
                                                className="validate"
                                                onChange={changeHandler}
                                            />
                                            <label htmlFor="password">Password</label>
                                        </div>
                                        <div className="input-field col s12">
                                            <select 
                                                name="role"
                                                value={form.role}
                                                onChange={changeHandler}
                                                className="browser-default"
                                            >
                                                <option value="Admin">Admin</option>
                                                <option value="Ppc">Ppc</option>
                                                <option value="Farm">Farm</option>
                                            </select>
                                            <label>Role</label>
                                        </div>
                                        <div className="row">
                                            <button
                                                className="login-reg-button"
                                                onClick={registerHandler}
                                            >
                                                Sign Up
                                            </button>
                                            <Link to="/login" className="btn-outline btn-reg login-reg-link">Have Account?</Link>
                                        </div>
                                    </div>
                                </form> 
                            </Route>*/}
                        </div>
                    </div>
                </React.Fragment>
            </Switch>
        </BrowserRouter>
    );
}

export default AuthPage;
