import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'; // Імпорт контексту автентифікації
import './BurgerMenu.scss';

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {role, name } = useContext(AuthContext); // Отримання ролі користувача з контексту
  const getRoleClass = (role) => {
    switch (role) {
        case 'Admin':
            return 'admin';
        case 'Ppc':
            return 'ppc';
        case 'Farm':
            return 'farm';
        default:
            return 'default';
    }
};
  const toggleMenu = () => {
    setIsOpen(!isOpen);
    const rootElement = document.getElementById('blured');
    if (rootElement) {
      rootElement.classList.toggle('blur');
    }
  };

  const closeMenu = () => {
    setIsOpen(false); 
    const rootElement = document.getElementById('blured');
    if (rootElement) {
      rootElement.classList.remove('blur');
    }
  };

  return (
    <>
        <div className={`burger-menu ${isOpen ? 'open' : ''}`}>
          <div className="burger-icon" onClick={toggleMenu}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
          <div className="menu-items">
            <div className='nav-roles-name'>
                <div className='nav-roles-name-style'>
                                  {name}.
                </div>
                <div className={`nav-name ${getRoleClass(role)}`}>
                                  {role}
                </div>
            </div>
            <ul>
              <Link to="/" onClick={closeMenu}><li>Main</li></Link> 
              
              {/* Пункти меню, видимі лише для ролей Admin і Ppc */}
              {role !== 'Farm' && (
                <>
                  <Link to="/main" onClick={closeMenu}><li>Domains Add</li></Link>
                  <Link to="/links" onClick={closeMenu}><li>Track Links</li></Link>
                  <Link to="/adv" onClick={closeMenu}><li>Descriptions & Titles</li></Link>
                  <Link to="/landing" onClick={closeMenu}><li>Lendings Order</li></Link>
                </>
              )}
              
              {/* Пункт меню, видимий для всіх ролей */}
              <Link to="/instr" onClick={closeMenu}><li>Instructions</li></Link>
            </ul>
          </div>
        </div>
    </>
  );
};

export default BurgerMenu;
