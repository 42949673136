import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import ListSection from './ListSection'; // перенесемо компонент ListSection у окремий файл

const BrandTitlesAndDescriptionsPage = () => {
    const [titleBrands, setTitleBrands] = useState([]);
    const [descrBrands, setDescrBrands] = useState([]);
    const [brandBlocks, setBrandBlocks] = useState([]);
    const [deletingTitleBrand, setDeletingTitleBrand] = useState(null);
    const [editingTitleBrand, setEditingTitleBrand] = useState(null);
    const [newTitleBrandText, setNewTitleBrandText] = useState('');
    const [deletingDescrBrand, setDeletingDescrBrand] = useState(null);
    const [editingDescrBrand, setEditingDescrBrand] = useState(null);
    const [newDescrBrandText, setNewDescrBrandText] = useState('');
    const [confirmingDeleteBlock, setConfirmingDeleteBlock] = useState(null); // новий стан для підтвердження видалення блоку

    const fetchItems = useCallback(async (endpoint, setState) => {
        try {
            const response = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/${endpoint}`, { headers: { 'Content-Type': 'application/json' } });
            setState(response.data);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const chunkArray = (array, size) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += size) {
            chunks.push(array.slice(i, i + size));
        }
        return chunks;
    };

    const fetchBlocks = useCallback(async () => {
        try {
            const [titleBrandsResponse, descrBrandsResponse] = await Promise.all([
                axios.get('https://spptesti-2e99c730e09e.herokuapp.com/api/titleBrand', { headers: { 'Content-Type': 'application/json' } }),
                axios.get('https://spptesti-2e99c730e09e.herokuapp.com/api/descrBrand', { headers: { 'Content-Type': 'application/json' } })
            ]);

            setTitleBrands(titleBrandsResponse.data);
            setDescrBrands(descrBrandsResponse.data);

            const newBrandBlocks = chunkArray(titleBrandsResponse.data, 15).map((titleBrandsChunk, index) => ({
                titleBrands: titleBrandsChunk,
                descrBrands: descrBrandsResponse.data.slice(index * 5, (index + 1) * 5)
            }));

            setBrandBlocks(newBrandBlocks);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        fetchBlocks();
    }, [fetchBlocks]);

    const addBrandBlock = async () => {
        try {
            const newTitleBrands = Array.from({ length: 15 }, () => ({ text: 'New Title Brand' }));
            const newDescrBrands = Array.from({ length: 4 }, () => ({ text: 'New Description Brand' }));

            const [titleBrandsResponse, descrBrandsResponse] = await Promise.all([
                axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/titleBrand/add-multiple', { items: newTitleBrands }),
                axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/descrBrand/add-multiple', { items: newDescrBrands })
            ]);

            setTitleBrands([...titleBrands, ...titleBrandsResponse.data]);
            setDescrBrands([...descrBrands, ...descrBrandsResponse.data]);
            setBrandBlocks([...brandBlocks, { titleBrands: titleBrandsResponse.data, descrBrands: descrBrandsResponse.data }]);
        } catch (error) {
            console.log('Error in addBrandBlock:', error);
        }
    };

    const removeBrandBlock = async (blockIndex) => {
        try {
            const block = brandBlocks[blockIndex];
            const titleBrandIds = block.titleBrands.map(item => item._id);
            const descrBrandIds = block.descrBrands.map(item => item._id);

            await Promise.all([
                axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/titleBrand/delete-multiple', { ids: titleBrandIds }),
                axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/descrBrand/delete-multiple', { ids: descrBrandIds })
            ]);

            const updatedBrandBlocks = [...brandBlocks];
            updatedBrandBlocks.splice(blockIndex, 1);
            setBrandBlocks(updatedBrandBlocks);

            setTitleBrands(titleBrands.filter(item => !titleBrandIds.includes(item._id)));
            setDescrBrands(descrBrands.filter(item => !descrBrandIds.includes(item._id)));
            setConfirmingDeleteBlock(null); // Закриваємо підтвердження видалення після успішного видалення
        } catch (error) {
            console.log('Error in removeBrandBlock:', error);
        }
    };

    return (
        <div className="info" id="blured">
            <div className="title-reg-box-button">
                <button className="title-reg-button-adv" onClick={addBrandBlock}>Add Brand Block</button>
            </div>
            <div className="info__box">
                {brandBlocks.map((block, index) => (
                    <div key={index} className="info__box-section">
                        <ListSection
                            title="Title Brands"
                            items={block.titleBrands}
                            setItems={(updatedItems) => {
                                const newBrandBlocks = [...brandBlocks];
                                newBrandBlocks[index].titleBrands = updatedItems;
                                setBrandBlocks(newBrandBlocks);
                            }}
                            itemText={newTitleBrandText}
                            setItemText={setNewTitleBrandText}
                            editingItem={editingTitleBrand}
                            setEditingItem={setEditingTitleBrand}
                            deletingItem={deletingTitleBrand}
                            setDeletingItem={setDeletingTitleBrand}
                            chunkSize={15}
                            apiEndpoint="titleBrand"
                            removeBlock={removeBrandBlock}
                            blockIndex={index}
                        />
                        <button onClick={() => setConfirmingDeleteBlock(index)} className='title-page-delete-button'>
                            <i className="material-icons">delete</i>
                        </button>
                        {confirmingDeleteBlock === index && (
                            <div className="confirm-delete">

                                <button onClick={() => removeBrandBlock(index)}>Yes</button>
                                <button onClick={() => setConfirmingDeleteBlock(null)}>No</button>
                            </div>
                        )}
                        <ListSection
                            title="Description Brands"
                            items={block.descrBrands}
                            setItems={(updatedItems) => {
                                const newBrandBlocks = [...brandBlocks];
                                newBrandBlocks[index].descrBrands = updatedItems;
                                setBrandBlocks(newBrandBlocks);
                            }}
                            itemText={newDescrBrandText}
                            setItemText={setNewDescrBrandText}
                            editingItem={editingDescrBrand}
                            setEditingItem={setEditingDescrBrand}
                            deletingItem={deletingDescrBrand}
                            setDeletingItem={setDeletingDescrBrand}
                            chunkSize={5}
                            apiEndpoint="descrBrand"
                            removeBlock={removeBrandBlock}
                            blockIndex={index}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BrandTitlesAndDescriptionsPage;
