import React from 'react';
import { Link} from 'react-router-dom';
import './AdvPage.scss';

const AdvPage = () => {
    return (
        <div className="adv" id='blured'>
            <div className="adv__wrappeer">
                <div className="adv__list">
                    <div className="adv__list-item shine-button">
                        <h1>Ukraine</h1>
                        <div>
                            <Link to='/titleUkr' className='adv__list-item-button'>
                                <button className='adv__button-advert'>Advert</button>
                            </Link>
                            <Link to='/titleBrandUkr' className='adv__list-item-button'>
                                <button className='adv__button-advert'>Advert Brand</button>
                            </Link>
                        </div>
                    </div>
                    <div className="adv__list-item shine-button">
                        <h1>Poland</h1>
                        <div>
                            <Link to='/titlePoland' className='adv__list-item-button'>
                                <button className='adv__button-advert'>Advert</button>
                            </Link>
                            <Link to='/titleBrandPoland' className='adv__list-item-button'>
                                <button className='adv__button-advert'>Advert Brand</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdvPage;



