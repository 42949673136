import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Document = ({ blogId, onNewContent }) => {
  const [documents, setDocuments] = useState([]);
  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/documents/${blogId}`);
        setDocuments(response.data);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    fetchDocuments();
  }, [blogId]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('document', file);
    formData.append('blogId', blogId);

    try {
      const response = await axios.post(`https://spptesti-2e99c730e09e.herokuapp.com/api/documents/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setDocuments([...documents, response.data]);
      setFile(null);
      onNewContent(response.data, 'document');
    } catch (error) {
      console.error('Error uploading document:', error);
    }
  };

  const handleDownload = async (id, name) => {
    try {
      const response = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/documents/${id}/download`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading document:', error);
    }
  };

  return (
    <div className="image-upload">
    <div className="icon-field">
          <i className='material-icons'>folder</i>
    </div>
    <div className="form-group">
    <input type="file" onChange={handleFileChange} />
    <button onClick={handleUpload}>Upload Document</button>
    </div>
  </div>
  );
};

export default Document;
