import React from 'react';
import axios from 'axios';
import copy from './TitlePage/copy.png';
import './TitlesAndDescriptionsPage.scss';

const ListSection = ({
    title,
    items,
    setItems,
    itemText,
    setItemText,
    editingItem,
    setEditingItem,
    deletingItem,
    setDeletingItem,
    chunkSize,
    apiEndpoint,
    removeBlock,
    blockIndex
}) => {
    const updateItem = async (id, newText) => {
        try {
            await axios.put(`https://spptesti-2e99c730e09e.herokuapp.com/api/${apiEndpoint}/update/${id}`, { text: newText });
            setItems(items.map(item => item._id === id ? { ...item, text: newText } : item));
            setEditingItem(null);
        } catch (error) {
            console.log(error);
        }
    };

  

    const handleEditItem = (item) => {
        setEditingItem(item);
        setItemText(item.text);
    };

    const handleCancelEdit = () => {
        setEditingItem(null);
        setItemText('');
    };

    const handleTextChange = (e) => {
        setItemText(e.target.value);
    };

    const copyText = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => console.log('Text copied to clipboard:', text))
            .catch((err) => console.error('Unable to copy text:', err));
    };

    const chunkArray = (array, size) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += size) {
            chunks.push(array.slice(i, i + size));
        }
        return chunks;
    };

    const itemChunks = chunkArray(items, chunkSize);

    return (
        <div className="titles-page">
            {itemChunks.map((chunk, chunkIndex) => (
                <div key={chunkIndex}>
                    <h4 className="descr-number">{title} {blockIndex + 1}</h4>
                    
                    <div className="todos todos-container ">
                        {chunk.map((item, index) => (
                            <div className="row flex todos-item" key={item._id}>
                                <div className="col todos-num">{index + 1}</div>
                                {editingItem && editingItem._id === item._id ? (
                                    <div className="col todos-text">
                                        <input type="text" value={itemText} onChange={handleTextChange} />
                                        <button onClick={() => updateItem(item._id, itemText)} className="confirmEdit-save">Save</button>
                                        <button onClick={handleCancelEdit} className="confirmEdit-cancel">Cancel</button>
                                    </div>
                                ) : (
                                    <div className="col todos-text">{item.text}</div>
                                )}
                                <div className="col todos-buttons">
                                    <i className="material-icons blue-text" onClick={() => handleEditItem(item)}>edit</i>
                                    <button className="copy-button" onClick={() => copyText(item.text)}>
                                        <img src={copy} alt="Copy" />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ListSection;
