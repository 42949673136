import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/theme-monokai';
import './CodeField.scss';

const CodeField = ({ blogId, onNewContent }) => {
  const [code, setCode] = useState('');
  const [language, setLanguage] = useState('html');
  const [codes, setCodes] = useState([]);
  const [isEditorVisible, setIsEditorVisible] = useState(false);

  const fetchCodes = async () => {
    try {
      const response = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/code/${blogId}`);
      setCodes(response.data);
    } catch (error) {
      console.error('Error fetching codes:', error);
    }
  };

  useEffect(() => {
    fetchCodes();
  }, [blogId]);

  const handleCodeChange = (newValue) => {
    setCode(newValue);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/code/save', { content: code, language, blogId });
      console.log('Saved code:', response.data);
      setCode('');
      setLanguage('html');
      fetchCodes();
      setIsEditorVisible(false);
      onNewContent(response.data, 'code');
    } catch (error) {
      console.error('Error saving code:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://spptesti-2e99c730e09e.herokuapp.com/api/code/${blogId}/${id}`);
      fetchCodes();
    } catch (error) {
      console.error('Error deleting code:', error);
    }
  };

  const toggleEditorVisibility = () => {
    setIsEditorVisible(!isEditorVisible);
  };

  return (
    <div className="code-field" id="blured">
      <div className="icon-field">
          <i className='material-icons'>code</i>
      </div>
        <form onSubmit={handleSubmit}>
          <div >
            <AceEditor
              mode={language}
              theme="monokai"
              onChange={handleCodeChange}
              name="code_editor"
              value={code}
              editorProps={{ $blockScrolling: true }}
              setOptions={{ useWorker: false }}
              style={{ width: '100%', height: '300px', background: '#1c1c3d'}}
            />
          </div>
          <button type="submit" className="title-reg-button">Save Code</button>
        </form>
    </div>
  );
};

export default CodeField;
