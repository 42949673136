import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './YouTubeEmbed.scss';

const YouTubeEmbed = ({ blogId, onNewContent }) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [title, setTitle] = useState('');
  const [savedVideos, setSavedVideos] = useState([]);
  const [isEditorVisible, setIsEditorVisible] = useState(false);

  useEffect(() => {
    fetchSavedVideos();
  }, [blogId]);

  const fetchSavedVideos = async () => {
    try {
      const response = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/videoUrl/${blogId}`);
      setSavedVideos(response.data);
    } catch (error) {
      console.error('Error fetching saved videos:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'videoUrl') {
      setVideoUrl(value);
    } else if (name === 'title') {
      setTitle(value);
    }
  };

  const handleEmbed = async () => {
    const videoId = extractVideoId(videoUrl);
    if (videoId) {
      const embedUrl = `https://www.youtube.com/embed/${videoId}`;
      try {
        const response = await axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/videoUrl/add', { url: embedUrl, title,  blogId });
        setVideoUrl('');
        setTitle('');
        setSavedVideos(prevVideos => [response.data, ...prevVideos]);
        onNewContent(response.data, 'video');
      } catch (error) {
        console.error('Error saving video URL:', error);
      }
    } else {
      alert('Invalid YouTube URL');
    }
  };

  const extractVideoId = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  

  const toggleEditorVisibility = () => {
    setIsEditorVisible(!isEditorVisible);
  };

  return (
    <div className="youtube-embed">
    <div className="icon-field">
          <i className='material-icons'>movie</i>
      </div>
      
        <div className="form-group">
          <label htmlFor="youtube-url">Enter YouTube URL:</label>
          <input
            type="text"
            id="youtube-url"
            name="videoUrl"
            value={videoUrl}
            onChange={handleInputChange}
            placeholder="https://www.youtube.com/watch?v=example"
          />
          <button onClick={handleEmbed}>Embed Video</button>
        </div>
      
    </div>
  );
};

export default YouTubeEmbed;
