import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './LandingPage.scss';
import Dropdown from '../../components/Dropdown/Dropdown';
import { AuthContext } from '../../context/AuthContext'; // Import AuthContext

const LandingPage = () => {

  const [order, setOrder] = useState({
    quantity: 1,
    geolocation: 'UKRAINE',
    banner: 'PORADNIK',
    description: '',
    customer: '',
    landingType: '',
    fakeGoogle: false, 
  });

  const [orders, setOrders] = useState([]);
  const [expanded, setExpanded] = useState({}); 
  const { role, name } = useContext(AuthContext); 
  const [editOrderId, setEditOrderId] = useState(null); 
  const [editedOrder, setEditedOrder] = useState({ quantity: '', description: '' }); 
  const [formVisible, setFormVisible] = useState(true); 

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://spptesti-2e99c730e09e.herokuapp.com/api/orders');
        setOrders(response.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    fetchOrders();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setOrder({
        ...order,
        [name]: checked, 
      });
    } else if (type === 'radio') {
      setOrder({ ...order, landingType: value });
    } else {
      setOrder({ ...order, [name]: value });
    }
  };

  const handleSelectChange = (name, value) => {
    setOrder({ ...order, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/orders', {
        ...order,
        customer: name,
        status: 'Pending',
        landingType: order.landingType,
        fakeGoogle: order.fakeGoogle, 
      });
      setOrders([...orders, response.data]);
    } catch (error) {
      console.error('Error creating order:', error);
    }
  };

  const handleDeleteOrder = async (id) => {
    try {
      await axios.delete(`https://spptesti-2e99c730e09e.herokuapp.com/api/orders/${id}`);
      setOrders(orders.filter((order) => order._id !== id));
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };

  const handleStatusChange = async (id, newStatus) => {
    try {
      const response = await axios.put(`https://spptesti-2e99c730e09e.herokuapp.com/api/orders/${id}`, { status: newStatus });
      setOrders(
        orders.map((order) => (order._id === id ? { ...order, status: newStatus } : order))
      );
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const getStatusClassName = (status) => {
    switch (status) {
      case 'Pending':
        return 'landing-page__status--waiting';
      case 'In Progress':
        return 'landing-page__status--in-progress';
      case 'Done':
        return 'landing-page__status--completed';
      default:
        return '';
    }
  };

  const toggleExpand = (id) => {
    setExpanded((prevState) => ({ ...prevState, [id]: !prevState[id] })); 
  };

  const toggleFormVisibility = () => {
    setFormVisible(!formVisible); 
  };

  
  const handleEditOrder = (order) => {
    setEditOrderId(order._id); 
    setEditedOrder({ quantity: order.quantity, description: order.description });
  };

 
  const handleSaveEdit = async (id) => {
    try {
      const response = await axios.put(`https://spptesti-2e99c730e09e.herokuapp.com/api/orders/${id}`, editedOrder);
      setOrders(
        orders.map((order) => (order._id === id ? { ...order, ...editedOrder } : order))
      );
      setEditOrderId(null);
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditedOrder({ ...editedOrder, [name]: value });
  };

  return (
    <div className='landing-page'>
      <h1 className='landing-page__title'>Order Landing</h1>
      <button onClick={toggleFormVisibility} className='landing-page__toggle-button'>
        {formVisible ?  (<> <i className="material-icons">close</i>  </> ) : (<> <i className="material-icons">edit</i> </>) }
      </button>

      {formVisible && (
        <form onSubmit={handleSubmit} className='landing-page__form'>
          <div className='landing-page__form-input'>
            <p>Landing Count</p>
            <input
              placeholder='Count'
              className='landing-page__form-input'
              id='quantity'
              name='quantity'
              value={order.quantity}
              onChange={handleInputChange}
              min='1'
            />
          </div>
          <div className='drop-container'>
            <Dropdown
              className="landing-page__form-dropdown"
              label="Geo"
              options={['UKRAINE', 'POLAND', 'ESTONIA', 'SLOVENIA', 'DEUTCHLAND', 'DENMARK', 'SPAIN']}
              selected={order.geolocation}
              onSelect={(value) => handleSelectChange('geolocation', value)}
            />
            <Dropdown
              className="landing-page__form-dropdown"
              label="Banner"
              options={['Poradnik ','No Rating']}
              selected={order.banner}
              onSelect={(value) => handleSelectChange('banner', value)}
            />
          </div>
          <div className="landing-page__form-checkboxes">
            <div>
              <label>
                <input
                  type="radio"
                  name="landingType"
                  value="Сірий + Білий на одній сторінці"
                  checked={order.landingType === 'Сірий + Білий на одній сторінці'}
                  onChange={handleInputChange}
                  className="with-gap"
                />
                <span>Сірий + Білий на одній сторінці</span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="landingType"
                  value="Сірий + Білий на окремих сторінках"
                  checked={order.landingType === 'Сірий + Білий на окремих сторінках'}
                  onChange={handleInputChange}
                  className="with-gap"
                />
                <span>Сірий + Білий на окремих сторінках</span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="landingType"
                  value="Білий лендінг"
                  checked={order.landingType === 'Білий лендінг'}
                  onChange={handleInputChange}
                  className="with-gap"
                />
                <span>Білий лендінг</span>
              </label>
            </div>
            <div>
              <label>
                <input
                  className='check-fake'
                  type="checkbox"
                  name="fakeGoogle"
                  checked={order.fakeGoogle}
                  onChange={handleInputChange}
                />
                <span>+ Фейк Гугл</span>
              </label>
            </div>
          </div>
          <div className='landing-page__form-input'>
            <label htmlFor='description' className='landing-page__label'>Description</label>
            <textarea
              className='landing-page__form-input-textarea'
              id='description'
              placeholder='Description'
              name='description'
              value={order.description}
              onChange={handleInputChange}
            />
          </div>
          <button type='submit' className='landing-page__orders-item-delete-button'>Замовити</button>
        </form>
      )}

      <h2 className='landing-page__title'>Queue</h2>
      <div className='landing-page__orders'>
        {orders.map((order, index) => (
          <div key={order._id} className='landing-page__orders-item'>
            <div className='landing-page__orders-item-box'>
              <div className={`landing-page__status ${getStatusClassName(order.status)}`}>
                <label>Статус:</label>
                {role === 'Admin' ? (
                  <>
                    <span>{order.status}</span>
                    <Dropdown
                      className="width-drop"
                      options={['Pending', 'In Progress', 'Done']}
                      selected={order.status}
                      onSelect={(value) => handleStatusChange(order._id, value)}
                    />
                  </>
                ) : (
                  <span>{order.status}</span>
                )}
              </div>
              <p>№{index + 1}</p>
              <p><span>Date of order:</span> {new Date(order.createdAt).toLocaleString()}</p>
              <p><span>Count:</span> {order.quantity}</p>
              <p><span>Geo:</span> {order.geolocation}</p>
              <p><span>Banner:</span> {order.banner}</p>
              <p><span>Customer:</span> {order.customer}</p>
              <p><span>Landing Type:</span> {order.landingType}</p>
              <p><span>Fake Google:</span> {order.fakeGoogle ? 'Yes' : 'No'}</p>
              <div
                className={`landing-page__orders-item-description ${
                  expanded[order._id] ? 'landing-page__orders-item-description--expanded' : 'landing-page__orders-item-description--collapsed'
                }`}
              >
                <span>Description:</span> {order.description}
              </div>
              <button
                onClick={() => toggleExpand(order._id)}
                className='landing-page__orders-item-toggle-button'
              >
                {expanded[order._id] ? (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-chevron-up"
                      width="24"
                      height="24"
                    >
                      <polyline points="18 15 12 9 6 15"></polyline>
                    </svg>
                  </>
                ) : (
                  <>
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-chevron-down"
                      width="24"
                      height="24"
                    >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                  </>
                )}
              </button>
              {editOrderId === order._id ? (
                <div>
                  <button onClick={() => handleSaveEdit(order._id)} className="todo__button todo__edit-button">
                                                <i className="material-icons">save</i>
                  </button>
                  <input
                    type="number"
                    name="quantity"
                    value={editedOrder.quantity}
                    onChange={handleEditInputChange}
                    className='landing-page__form-input'
                  />
                  <textarea
                    className='landing-page__form-input-textarea'
                    name="description"
                    value={editedOrder.description}
                    onChange={handleEditInputChange}
                  />
                </div>
              ) : (
                <div className='buttons-position'>
                  <button onClick={() => handleEditOrder(order)} className="todo__button todo__edit-button">
                                                <i className="material-icons">edit</i>
                  </button>
                  <button onClick={() => handleDeleteOrder(order._id)} className="todo__button todo__edit-button">
                                                <i className="material-icons">delete</i>
                  </button>
                  
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LandingPage;
