import React, { useMemo, useEffect, useState } from 'react';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter } from 'react-router-dom';
import { useRoutes } from './routes';
import { AuthContext } from '../src/context/AuthContext';
import { useAuth } from './hooks/auth.hook';
import BurgerMenu from './components/BurgerMenu/BurgerMenu';
import './App.scss';
import axios from 'axios';

function App() {
  const { login, logout, token, userId, isReady } = useAuth();
  const [role, setRole] = useState(null);
  const [name, setName] = useState(null); // Додаємо state для імені
  const isLogin = useMemo(() => !!token, [token]);
  const routes = useRoutes(isLogin, role);

  useEffect(() => {
    if (isLogin && userId) {
      axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/users/${userId}/role`)
        .then(response => {
          setRole(response.data.role);
        })
        .catch(error => {
          console.error('Error fetching user role:', error);
        });
    }
  }, [isLogin, userId]);

  useEffect(() => {
    if (isLogin && userId) {
      axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/users/${userId}/name`)
        .then(response => {
          setName(response.data.name); // Зберігаємо ім'я у state
        })
        .catch(error => {
          console.error('Error fetching user name:', error);
        });
    }
  }, [isLogin, userId]);

  if (!isReady) {
    return <div>Loading...</div>; 
  }

  return (
    <AuthContext.Provider value={{ login, logout, token, userId, isReady, isLogin, role, name }}>
      <div className="app">
        <BrowserRouter>
          <Navbar />
          {isLogin ? <BurgerMenu /> : null}
          {routes}
        </BrowserRouter>
      </div>
    </AuthContext.Provider>
  );
}

export default App;
