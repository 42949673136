import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TextField.scss'

const TextField = ({ blogId, onNewContent }) => {
  const [text, setText] = useState('');
  const [texts, setTexts] = useState([]);
  const [isEditorVisible, setIsEditorVisible] = useState(false);

  const fetchTexts = async () => {
    try {
      const response = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/text/${blogId}`);
      setTexts(response.data);
    } catch (error) {
      console.error('Error fetching texts:', error);
    }
  };

  useEffect(() => {
    fetchTexts();
  }, [blogId]);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/text/save', { content: text, blogId });
      console.log('Saved text:', response.data);
      setText('');
      fetchTexts();
      setIsEditorVisible(false);
      onNewContent(response.data, 'text');
    } catch (error) {
      console.error('Error saving text:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://spptesti-2e99c730e09e.herokuapp.com/api/text/${blogId}/${id}`);
      fetchTexts();
    } catch (error) {
      console.error('Error deleting text:', error);
    }
  };

  const toggleEditorVisibility = () => {
    setIsEditorVisible(!isEditorVisible);
  };

  return (
    <div className="text-field-container">
      <div className="icon-field">
          <i className='material-icons'>format_size </i>
      </div>
        <form onSubmit={handleSubmit} className='text-add-form'>
          <textarea
          
            value={text}
            onChange={handleTextChange}
            placeholder="Enter your text here"
            className="text-area"
          />
          <button type="submit" className="title-reg-button">Save Text</button>
        </form>
    </div>
  );
};

export default TextField;
