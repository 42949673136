import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import ListSection from './ListSection';
import './TitlesAndDescriptionsPage.scss';

const TitlesAndDescriptionsPage = () => {
    const [titles, setTitles] = useState([]);
    const [descrs, setDescrs] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [deletingTitle, setDeletingTitle] = useState(null);
    const [editingTitle, setEditingTitle] = useState(null);
    const [newTitleText, setNewTitleText] = useState('');
    const [deletingDescr, setDeletingDescr] = useState(null);
    const [editingDescr, setEditingDescr] = useState(null);
    const [newDescrText, setNewDescrText] = useState('');
    const [confirmingDeleteBlock, setConfirmingDeleteBlock] = useState(null); // новий стан для підтвердження видалення блоку

    const chunkArray = (array, size) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += size) {
            chunks.push(array.slice(i, i + size));
        }
        return chunks;
    };

    const fetchBlocks = useCallback(async () => {
        try {
            const [titlesResponse, descrsResponse] = await Promise.all([
                axios.get('https://spptesti-2e99c730e09e.herokuapp.com/api/title', { headers: { 'Content-Type': 'application/json' } }),
                axios.get('https://spptesti-2e99c730e09e.herokuapp.com/api/descr', { headers: { 'Content-Type': 'application/json' } })
            ]);

            setTitles(titlesResponse.data);
            setDescrs(descrsResponse.data);

            const newBlocks = chunkArray(titlesResponse.data, 15).map((titlesChunk, index) => ({
                titles: titlesChunk,
                descrs: descrsResponse.data.slice(index * 5, (index + 1) * 5)
            }));

            setBlocks(newBlocks);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        fetchBlocks();
    }, [fetchBlocks]);

    const addBlock = async () => {
        try {
            const newTitles = Array.from({ length: 15 }, () => ({ text: 'New Title' }));
            const newDescrs = Array.from({ length: 4 }, () => ({ text: 'New Description' }));

            const [titlesResponse, descrsResponse] = await Promise.all([
                axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/title/add-multiple', { items: newTitles }),
                axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/descr/add-multiple', { items: newDescrs })
            ]);

            setTitles([...titles, ...titlesResponse.data]);
            setDescrs([...descrs, ...descrsResponse.data]);
            setBlocks([...blocks, { titles: titlesResponse.data, descrs: descrsResponse.data }]);
        } catch (error) {
            console.log('Error in addBlock:', error);
        }
    };

    const removeBlock = async (blockIndex) => {
        try {
            const block = blocks[blockIndex];
            const titleIds = block.titles.map(item => item._id);
            const descrIds = block.descrs.map(item => item._id);

            await Promise.all([
                axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/title/delete-multiple', { ids: titleIds }),
                axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/descr/delete-multiple', { ids: descrIds })
            ]);

            const updatedBlocks = [...blocks];
            updatedBlocks.splice(blockIndex, 1);
            setBlocks(updatedBlocks);

            setTitles(titles.filter(item => !titleIds.includes(item._id)));
            setDescrs(descrs.filter(item => !descrIds.includes(item._id)));
            setConfirmingDeleteBlock(null); // Закриваємо підтвердження видалення після успішного видалення
        } catch (error) {
            console.log('Error in removeBlock:', error);
        }
    };

    return (
        <div className="info" id="blured">
            <div className="title-reg-box-button">
                <button className="title-reg-button-adv" onClick={addBlock}>Add Block</button>
            </div>
            <div className="info__box">
                {blocks.map((block, index) => (
                    <div key={index} className="info__box-section">
                        <ListSection
                            title="Titles"
                            items={block.titles}
                            setItems={(updatedItems) => {
                                const newBlocks = [...blocks];
                                newBlocks[index].titles = updatedItems;
                                setBlocks(newBlocks);
                            }}
                            itemText={newTitleText}
                            setItemText={setNewTitleText}
                            editingItem={editingTitle}
                            setEditingItem={setEditingTitle}
                            deletingItem={deletingTitle}
                            setDeletingItem={setDeletingTitle}
                            chunkSize={15}
                            apiEndpoint="title"
                            removeBlock={removeBlock}
                            blockIndex={index}
                        />
                        <button onClick={() => setConfirmingDeleteBlock(index)} className='title-page-delete-button'>
                            <i className="material-icons">delete</i>
                        </button>
                        {confirmingDeleteBlock === index && (
                            <div className="confirm-delete">
                                <button onClick={() => removeBlock(index)}>Yes</button>
                                <button onClick={() => setConfirmingDeleteBlock(null)}>No</button>
                            </div>
                        )}
                        <ListSection
                            title="Descriptions"
                            items={block.descrs}
                            setItems={(updatedItems) => {
                                const newBlocks = [...blocks];
                                newBlocks[index].descrs = updatedItems;
                                setBlocks(newBlocks);
                            }}
                            itemText={newDescrText}
                            setItemText={setNewDescrText}
                            editingItem={editingDescr}
                            setEditingItem={setEditingDescr}
                            deletingItem={deletingDescr}
                            setDeletingItem={setDeletingDescr}
                            chunkSize={5}
                            apiEndpoint="descr"
                            removeBlock={removeBlock}
                            blockIndex={index}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TitlesAndDescriptionsPage;
