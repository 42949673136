import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './InstrPage.scss';
import hacker from './InstrPage/hacker.png';
import { AuthContext } from '../../context/AuthContext';

const InstrPage = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [newPost, setNewPost] = useState({ title: '', imageUrl: '', category: '' });
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [openCategory, setOpenCategory] = useState(null);

  const { role, name } = useContext(AuthContext);

  useEffect(() => {
    axios.get('https://spptesti-2e99c730e09e.herokuapp.com/api/blogPosts')
      .then(response => setBlogPosts(response.data))
      .catch(error => console.error('Error fetching blog posts:', error));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPost({ ...newPost, [name]: value });
  };
  const handleCreatePost = () => {
    const post = {
      ...newPost,
      authorName: name,  // Використовується ім'я з контексту
      imageUrl: newPost.imageUrl || hacker,
      createdAt: new Date().toISOString(),
    };

    axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/blogPosts', post)
      .then(response => {
        setBlogPosts(prev => [...prev, response.data]);
        setNewPost({ title: '', imageUrl: '', category: '' });
      })
      .catch(error => console.error('Error creating blog post:', error));
};

  const handleDeletePost = (id) => {
    axios.delete(`https://spptesti-2e99c730e09e.herokuapp.com/api/blogPosts/${id}`)
      .then(() => {
        setBlogPosts(prev => prev.filter(post => post._id !== id));
        setConfirmDelete(null);
      })
      .catch(error => console.error('Error deleting blog post:', error));
  };

  const categorizedPosts = {
    Lendings: blogPosts.filter(post => post.category === 'Lendings'),
    Farm: blogPosts.filter(post => post.category === 'Farm'),
    GoogleAds: blogPosts.filter(post => post.category === 'Google Ads')
  };

  const toggleCategory = (category) => {
    setOpenCategory(openCategory === category ? null : category);
  };

  return (
    <div className="instr" id="blured">
      <h1>Create Topic</h1>
      <div className='input-blog'>
        <input
          type="text"
          name="title"
          placeholder="Title"
          value={newPost.title}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="imageUrl"
          placeholder="Image URL"
          value={newPost.imageUrl}
          onChange={handleInputChange}
        />
        <select
          name="category"
          value={newPost.category}
          onChange={handleInputChange}
          className='category-select'
        >
          <option value="">Select Category</option>
          <option value="Lendings">Lendings</option>
          <option value="Farm">Farm</option>
          <option value="Google Ads">Google Ads</option>
        </select>
        <button onClick={handleCreatePost} className='title-reg-button'>Create Topic</button>
      </div>
      <div className="blog-columns">
        {(role === 'Admin' || role === 'Ppc') && (
          <>
            <div className="blog-column">
              <div onClick={() => toggleCategory('Lendings')} className='category-choice'>
                <p>Lendings</p>
                <p className='plus'>{openCategory === 'Lendings' ? '-' : '+'}</p>
              </div>
              <div className={`blog-row ${openCategory === 'Lendings' ? 'open' : ''}`}>
                {categorizedPosts.Lendings && categorizedPosts.Lendings.length > 0 ? (
                  categorizedPosts.Lendings.map((post, index) => (
                    <div key={index} className={`blog-post ${openCategory === 'Lendings' ? 'open' : ''}`}>
                      <Link to={`/blog/${post._id}`}>
                        <img src={post.imageUrl} alt={post.title} />
                        <div className='blog-post-text'>
                          <span className='create-info'>Author: {post.authorName}</span>
                          
                          <span className='create-info'>Created at:{new Date(post.createdAt).toLocaleString()}</span>
                 
                          <h4>{post.title}</h4>
                        </div>
                      </Link>
                      {confirmDelete === post._id ? (
                        <div className="confirm-delete-blog">
                          <p>Are you sure you want to delete this post?</p>
                          <div>
                            <button onClick={() => handleDeletePost(post._id)} className='confirm-button'>Yes</button>
                            <button onClick={() => setConfirmDelete(null)} className='cancel-button'>No</button>
                          </div>
                        </div>
                      ) : (
                        <button onClick={() => setConfirmDelete(post._id)} className='delete-blog-button'>Delete</button>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No posts available</p>
                )}
              </div>
            </div>

            <div className="blog-column">
              <div onClick={() => toggleCategory('GoogleAds')} className='category-choice'>
                <p>Google Ads</p>
                <p className='plus'>{openCategory === 'GoogleAds' ? '-' : '+'}</p>
              </div>
              <div className={`blog-row ${openCategory === 'GoogleAds' ? 'open' : ''}`}>
                {categorizedPosts.GoogleAds && categorizedPosts.GoogleAds.length > 0 ? (
                  categorizedPosts.GoogleAds.map((post, index) => (
                    <div key={index} className={`blog-post ${openCategory === 'GoogleAds' ? 'open' : ''}`}>
                      <Link to={`/blog/${post._id}`}>
                        <img src={post.imageUrl} alt={post.title} />
                        <div className='blog-post-text'>
                          <span className='create-info'>Author:</span>
                          <span> {post.authorName}</span>  {/* Використовується збережене ім'я */}
                          <span className='create-info'>Created at:</span>
                          <span> {new Date(post.createdAt).toLocaleString()}</span>
                          <h4>{post.title}</h4>
                        </div>
                      </Link>
                      {confirmDelete === post._id ? (
                        <div className="confirm-delete-blog">
                          <p>Are you sure you want to delete this post?</p>
                          <div>
                            <button onClick={() => handleDeletePost(post._id)} className='confirm-button'>Yes</button>
                            <button onClick={() => setConfirmDelete(null)} className='cancel-button'>No</button>
                          </div>
                        </div>
                      ) : (
                        <button onClick={() => setConfirmDelete(post._id)} className='delete-blog-button'>Delete</button>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No posts available</p>
                )}
              </div>
            </div>
          </>
        )}

        {(role === 'Admin' || role === 'Farm' || role === 'Ppc') && (
          <div className="blog-column">
            <div onClick={() => toggleCategory('Farm')} className='category-choice'>
              <p>Farm</p>
              <p className='plus'>{openCategory === 'Farm' ? '-' : '+'}</p>
            </div>
            <div className={`blog-row ${openCategory === 'Farm' ? 'open' : ''}`}>
              {categorizedPosts.Farm && categorizedPosts.Farm.length > 0 ? (
                categorizedPosts.Farm.map((post, index) => (
                  <div key={index} className={`blog-post ${openCategory === 'Farm' ? 'open' : ''}`}>
                   <Link to={`/blog/${post._id}`}>
                    <img src={post.imageUrl} alt={post.title} />
                    <div className='blog-post-text'>
                      <span className='create-info'>Author:</span>
                      <span> {post.authorName}</span>  {/* Використовується збережене ім'я */}
                      <span className='create-info'>Created at:</span>
                      <span> {new Date(post.createdAt).toLocaleString()}</span>
                      <h4>{post.title}</h4>
                    </div>
                  </Link>

                    {confirmDelete === post._id ? (
                      <div className="confirm-delete-blog">
                        <p>Are you sure you want to delete this post?</p>
                        <div>
                          <button onClick={() => handleDeletePost(post._id)} className='confirm-button'>Yes</button>
                          <button onClick={() => setConfirmDelete(null)} className='cancel-button'>No</button>
                        </div>
                      </div>
                    ) : (
                      <button onClick={() => setConfirmDelete(post._id)} className='delete-blog-button'>Delete</button>
                    )}
                  </div>
                ))
              ) : (
                <p>No posts available</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InstrPage;
