import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AceEditor from 'react-ace';
import CodeField from '../CodeField/CodeField';
import TextField from '../TextField/TextField';
import YouTubeEmbed from '../YouTubeEmbed/YouTubeEmbed';
import ImageUpload from '../ImageUpload/ImageUpload';
import Modal from '../Modal/Modal';
import Document from '../Document/Document';
import { AuthContext } from '../../context/AuthContext'; // Імпорт AuthContext
import './BlogPostPage.scss';
import setings from './settings-svgrepo-com.png';
import close from './close.png';

const BlogPostPage = ({ match, history }) => {
  const { id } = match.params;
  const [post, setPost] = useState(null);
  const [contents, setContents] = useState([]);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [visibleField, setVisibleField] = useState(null);
  const [editingContent, setEditingContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentToDelete, setContentToDelete] = useState(null);
  const [isEditingImage, setIsEditingImage] = useState(null);
  const [newImage, setNewImage] = useState(null);

  const { role } = useContext(AuthContext); // Використання useContext для отримання ролі

  useEffect(() => {
    const checkAccessAndFetchContent = async () => {
      try {
        // Перевіряємо доступ до блогу за категорією
        const postResponse = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/blogPosts/${id}`);
        const post = postResponse.data;

        if (role === 'Farm' && (post.category === 'Lendings' || post.category === 'Google Ads')) {
          // Якщо користувач не має доступу, перенаправляємо негайно
          history.push('/');
          return;
        }

        // Якщо доступ дозволено, завантажуємо вміст
        setPost(post);

        const [codeResponse, textResponse, videoResponse, imageResponse, documentResponse] = await Promise.all([
          axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/code/${id}`),
          axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/text/${id}`),
          axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/videoUrl/${id}`),
          axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/image/${id}`),
          axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/documents/${id}`)
        ]);

        const allContent = [
          ...codeResponse.data.map(item => ({ ...item, type: 'code' })),
          ...textResponse.data.map(item => ({ ...item, type: 'text' })),
          ...videoResponse.data.map(item => ({ ...item, type: 'video' })),
          ...imageResponse.data.map(item => ({ ...item, type: 'image' })),
          ...documentResponse.data.map(item => ({ ...item, type: 'document' }))
        ];
        allContent.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        setContents(allContent);
      } catch (error) {
        console.error('Error fetching content:', error);
        history.push('/'); // Перенаправлення у випадку помилки
      }
    };

    checkAccessAndFetchContent();
  }, [id, role, history]);


  const handleNewContent = (newContent, type) => {
    const updatedContents = [{ ...newContent, type }, ...contents];
    updatedContents.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    setContents(updatedContents);
  };

  const handleDelete = async () => {
    const { contentId, type } = contentToDelete;
    try {
      console.log(`Attempting to delete content with ID: ${contentId} and type: ${type}`);
      let url = `https://spptesti-2e99c730e09e.herokuapp.com/api/${type}/${id}/${contentId}`;
      if (type === 'image') {
        url = `https://spptesti-2e99c730e09e.herokuapp.com/api/image/${contentId}`;
      } else if (type === 'document') {
        url = `https://spptesti-2e99c730e09e.herokuapp.com/api/documents/${contentId}`;
      }
      await axios.delete(url);
      setContents(prevContents => prevContents.filter(content => content._id !== contentId));
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error deleting content:', error);
    }
  };

  const handleEdit = (content) => {
    setEditingContent(content);
  };

  const handleSaveEdit = async () => {
    try {
      const { _id, type, content: updatedContent, language } = editingContent;
      const url = `https://spptesti-2e99c730e09e.herokuapp.com/api/${type}/${_id}`;
      await axios.put(url, { content: updatedContent, language });
      setContents(prevContents =>
        prevContents.map(content =>
          content._id === _id ? { ...content, content: updatedContent } : content
        )
      );
      setEditingContent(null);
    } catch (error) {
      console.error('Error saving edited content:', error);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setNewImage(file);
  };

  const handleSaveImageEdit = async (imageId) => {
    const formData = new FormData();
    formData.append('image', newImage);

    try {
      const response = await axios.put(`https://spptesti-2e99c730e09e.herokuapp.com/api/image/${imageId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setContents(prevContents =>
        prevContents.map(content =>
          content._id === imageId ? { ...content, data: response.data.data } : content
        )
      );
      setIsEditingImage(null);
      setNewImage(null);
    } catch (error) {
      console.error('Error updating image:', error);
    }
  };

  const handleDocumentDownload = async (id, name) => {
    try {
      const response = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/documents/${id}/download`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading document:', error);
    }
  };

  const toggleFieldVisibility = (field) => {
    setVisibleField(prevField => (prevField === field ? null : field));
  };

  const confirmDelete = (contentId, type) => {
    setContentToDelete({ contentId, type });
    setIsModalOpen(true);
  };

  const renderTextWithLineBreaks = (text) => {
    return text.split('\n').map((item, key) => (
      <React.Fragment key={key}>
        {item}
        <br />
      </React.Fragment>
    ));
  };

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className="blog-post-page" id="blured">
      <h2>{post.title}</h2>
      
      <div className="saved-content">
        <button onClick={() => setIsDeleteVisible(!isDeleteVisible)} className="title-reg-button-par">
          <img src={isDeleteVisible ? close : setings} alt="" className='parameters' />
        </button>
        {contents.map(content => (
          <div key={content._id} className="content-block">
            {content.type === 'code' ? (
              editingContent && editingContent._id === content._id ? (
                <>
                  <AceEditor
                    mode={content.language}
                    theme="monokai"
                    name={`edit_code_editor_${content._id}`}
                    value={editingContent.content}
                    onChange={(value) => setEditingContent(prev => ({ ...prev, content: value }))}
                    className='ace-editor'
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{ useWorker: false }}
                    style={{ width: '80%', height: '400px', background: '#1c1c3d' }}
                  />
                  <div className={`delete-button-container ${isDeleteVisible ? 'visible' : ''}`}>
                    <button onClick={handleSaveEdit} className="title-page-delete-button-2">
                      <i className="material-icons">save</i>
                    </button>
                    <button onClick={() => setEditingContent(null)} className="title-page-delete-button-1">
                      <i className="material-icons">cancel</i>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <AceEditor
                    mode={content.language}
                    theme="monokai"
                    name={`view_code_editor_${content._id}`}
                    value={content.content}
                    readOnly
                    className='ace-editor'
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{ useWorker: false }}
                    style={{ width: '80%', height: '400px', background: '#1c1c3d' }}
                  />
                  <div className={`delete-button-container ${isDeleteVisible ? 'visible' : ''}`}>
                    <button onClick={() => confirmDelete(content._id, content.type)} className="title-page-delete-button-1">
                      <i className="material-icons">delete</i>
                    </button>
                    <button onClick={() => handleEdit(content)} className="title-page-delete-button-2">
                      <i className="material-icons">edit</i>
                    </button>
                  </div>
                </>
              )
            ) : content.type === 'text' ? (
              editingContent && editingContent._id === content._id ? (
                <>
                  <textarea
                    value={editingContent.content}
                    onChange={(e) => setEditingContent(prev => ({ ...prev, content: e.target.value }))}
                    className='text-blog'
                    
                  />
                  <div className={`delete-button-container ${isDeleteVisible ? 'visible' : ''}`}>
                    <button onClick={handleSaveEdit} className="title-page-delete-button-2">
                      <i className="material-icons">save</i>
                    </button>
                    <button onClick={() => setEditingContent(null)} className="title-page-delete-button-1">
                      <i className="material-icons">cancel</i>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <p className='text-blog'>{renderTextWithLineBreaks(content.content)}</p>
                  <div className={`delete-button-container ${isDeleteVisible ? 'visible' : ''}`}>
                    <button onClick={() => confirmDelete(content._id, content.type)} className="title-page-delete-button-1">
                      <i className="material-icons">delete</i>
                    </button>
                    <button onClick={() => handleEdit(content)} className="title-page-delete-button-2">
                      <i className="material-icons">edit</i>
                    </button>
                  </div>
                </>
              )
            ) : content.type === 'video' ? (
              <>
                <div className="video-container">
                  <iframe
                    className='video-player'
                    src={content.url}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <div className={`delete-button-container ${isDeleteVisible ? 'visible' : ''}`}>
                    <button onClick={() => confirmDelete(content._id, content.type)} className="title-page-delete-button-1">
                      <i className="material-icons">delete</i>
                    </button>
                  </div>
                </div>
              </>
            ) : content.type === 'image' ? (
              <>
                <div className="image-container">
                  <img src={`data:image/jpeg;base64,${content.data}`} alt="Uploaded" />
                  {isEditingImage === content._id ? (
                    <div>
                      <input type="file" onChange={handleImageChange} />
                      <div className={`delete-button-container ${isDeleteVisible ? 'visible' : ''}`}>
                        <button onClick={() => handleSaveImageEdit(content._id)} className="title-page-delete-button-2">
                          <i className="material-icons">save</i>
                        </button>
                        <button onClick={() => setIsEditingImage(null)} className="title-page-delete-button-1">
                          <i className="material-icons">cancel</i>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className={`delete-button-container ${isDeleteVisible ? 'visible' : ''}`}>
                      <button onClick={() => confirmDelete(content._id, content.type)} className="title-page-delete-button-1">
                        <i className="material-icons">delete</i>
                      </button>
                      <button onClick={() => setIsEditingImage(content._id)} className="title-page-delete-button-2">
                        <i className="material-icons">edit</i>
                      </button>
                    </div>
                  )}
                </div>
              </>
            ) : content.type === 'document' ? (
              <>
                <div className="document-container">
                  
                  <p><i className="material-icons">insert_drive_file</i>{content.name}</p>
                  <button onClick={() => handleDocumentDownload(content._id, content.name)} className="title-page-delete-button-2-doc">
                      <i className="material-icons">download</i>
                  </button>
                  <div className={`delete-button-container ${isDeleteVisible ? 'visible' : ''}`}>
                    <button onClick={() => confirmDelete(content._id, content.type)} className="title-page-delete-button-1">
                      <i className="material-icons">delete</i>
                    </button>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        ))}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleDelete}
      >
        <p>Are you sure you want to delete this content?</p>
      </Modal>
      <div className={`content-buttons ${isDeleteVisible ? 'visible' : ''}`}>
        <button onClick={() => toggleFieldVisibility('code')} className="title-reg-button">
          {visibleField === 'code' ? 'Hide Code Field' : 'Show Code Field'}
        </button>
        <button onClick={() => toggleFieldVisibility('text')} className="title-reg-button">
          {visibleField === 'text' ? 'Hide Text Field' : 'Show Text Field'}
        </button>
        <button onClick={() => toggleFieldVisibility('video')} className="title-reg-button">
          {visibleField === 'video' ? 'Hide Video Field' : 'Show Video Field'}
        </button>
        <button onClick={() => toggleFieldVisibility('image')} className="title-reg-button">
          {visibleField === 'image' ? 'Hide Image Field' : 'Show Image Field'}
        </button>
        <button onClick={() => toggleFieldVisibility('document')} className="title-reg-button">
          {visibleField === 'document' ? 'Hide File Field' : 'Show File Field'}
        </button>
      </div>
      <div className={`field-container ${visibleField === 'code' ? 'fade-in' : 'fade-out'}`}>
        {visibleField === 'code' && <CodeField blogId={id} onNewContent={handleNewContent} />}
      </div>
      <div className={`field-container ${visibleField === 'text' ? 'fade-in' : 'fade-out'}`}>
        {visibleField === 'text' && <TextField blogId={id} onNewContent={handleNewContent} />}
      </div>
      <div className={`field-container ${visibleField === 'video' ? 'fade-in' : 'fade-out'}`}>
        {visibleField === 'video' && <YouTubeEmbed blogId={id} onNewContent={handleNewContent} />}
      </div>
      <div className={`field-container ${visibleField === 'image' ? 'fade-in' : 'fade-out'}`}>
        {visibleField === 'image' && <ImageUpload blogId={id} onNewContent={handleNewContent} />}
      </div>
      <div className={`field-container ${visibleField === 'document' ? 'fade-in' : 'fade-out'}`}>
        {visibleField === 'document' && <Document blogId={id} onNewContent={handleNewContent} />}
      </div>
    </div>
  );
};

export default BlogPostPage;
