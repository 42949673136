import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ImageUpload.scss';

const ImageUpload = ({ blogId, onNewContent }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [images, setImages] = useState([]);
  const [isEditorVisible, setIsEditorVisible] = useState(false);

  useEffect(() => {
    fetchImages();
  }, [blogId]);

  const fetchImages = async () => {
    try {
      const response = await axios.get(`https://spptesti-2e99c730e09e.herokuapp.com/api/image/${blogId}`);
      setImages(response.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('blogId', blogId);

    try {
      const response = await axios.post('https://spptesti-2e99c730e09e.herokuapp.com/api/image/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setSelectedFile(null);
      onNewContent(response.data, 'image');
      fetchImages();
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const toggleEditorVisibility = () => {
    setIsEditorVisible(!isEditorVisible);
  };

  return (
    <div className="image-upload">
     <div className="icon-field">
          <i className='material-icons'>image</i>
      </div>
        <div className="form-group">
          <input type="file" onChange={handleFileChange} />
          <button onClick={handleUpload}>Upload Image</button>
        </div>
    </div>
  );
};

export default ImageUpload;